import mutations from './mutations'
import actions from './actions'
import state from './state'
import getters from './getters'
import { account } from './modules/account/index'
import { user } from './modules/user/index'
import { driver } from './modules/driver/index'
import { technical } from './modules/technical/index'
import { social_reason } from './modules/social_reason/index'
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
  modules: {
    account,
    social_reason,
    user,
    driver,
    technical
  }
}
