import { Module } from 'vuex'
// @ts-ignore
import { RootState } from '@/store/types'
// import { productos } from './productos'
// import { clientes } from './clientes'
import { eir_container } from './modules/eir_container'

export const eir: Module<any, RootState> = {
  namespaced: true,
  modules: {
    eir_container
    // customer,
    // quotation,
    // note_sale,
    // factura,
    // pago,
    // sat,
    // carta_porte,
    // dashboard
    // clientes,
    // cotizaciones
  }
}
