
import { ActionContext } from 'vuex'
import { State } from './state'
import objToFormData from '@/utils/objToFormData'
// import { IProducto } from '@/classes/Facturacion/IProduct'
const URL_ACTION = '/apis/user/social_reason/'
const actions = {
  async create ({ commit, dispatch }: ActionContext<State, any>, payload:any) {
    const formData = objToFormData(payload)
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'POST',
        data: formData
      },
      { root: true }
    )
    return data
  },
  async loadAll ({ commit, dispatch }: ActionContext<State, any>, payload = { search: '', page: 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'GET',
        params: {
          ...payload
        }
      },
      { root: true }
    )
    return data
  },
  async loadById ({ commit, dispatch }: ActionContext<State, any>, id:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${id}`,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async update ({ commit, dispatch }: ActionContext<State, any>, payload:any) {
    const formData = objToFormData(payload)
    console.log(formData)
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.id}`,
        method: 'PUT',
        data: formData
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async delete ({ commit, dispatch }: ActionContext<State, any>, id:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  }
}
export default actions
