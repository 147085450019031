
import { ActionContext } from 'vuex'
import { State } from './state'
import { DateTime } from 'luxon'
// import { ICustomer } from '@/classes/Facturacion/ICustomer'
const URL_ACTION = '/apis/eir/'
const actions = {
  async loadAll ({ commit, dispatch }: ActionContext<State, any>, payload = { search: '', page: 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'GET',
        params: {
          ...payload
        }
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async getById ({ commit, dispatch }: ActionContext<State, any>, id:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${id}`,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async create ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('PUSH', data)
    return data
  },
  async update ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    commit('UPDATE', data)
    return data
  },
  async delete ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    // commit('UPDATE', data)
    return data
  },
  async history ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}history/${payload}`,
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async dashboard ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}dashboard`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async getPDF ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const response = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}pdf/${payload.id}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/pdf'
        }
      },
      { root: true }
    )
    const blob = new Blob([response.data], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${payload.folio}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    window.URL.revokeObjectURL(url)

    return response
  }
}
export default actions
