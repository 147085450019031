/* eslint-disable @typescript-eslint/no-explicit-any */
import { PLATAFORM, PLATAFORM_RESOURCES, RESOURCES } from './RESOURCES'
import { State } from './state'

export const mutations = {
  SET_RESOURCES (
    state: State,
    { resources, rType, plataform, rPlatafrom }: { resources: any; rType: RESOURCES; plataform?: PLATAFORM; rPlatafrom: PLATAFORM_RESOURCES }
  ) {
    if (plataform) {
      state.PLATAFORM[plataform][rPlatafrom] = resources
    } else {
      state[rType] = resources
    }
  },
  SET_MANY_RESOURCES (
    state: State,
    { resources, rType }: { resources: any[]; rType: RESOURCES; }
  ) {
    // @ts-ignore
    state[rType].push(...resources)
  },
  UNSHIFT_RESOURCE (
    state: State,
    { item, rType, rPlatafrom, plataform }: { item: any; rType: RESOURCES; plataform?: PLATAFORM; rPlatafrom: PLATAFORM_RESOURCES }
  ) {
    if (plataform) {
      // @ts-ignore
      state.PLATAFORM[plataform][rPlatafrom].unshift(item)
    } else {
      // @ts-ignore
      state[rType].unshift(item)
    }
  },
  PUSH_RESOURCE (
    state: State,
    { item, rType, rPlatafrom, plataform }: { item: any; rType: RESOURCES; plataform?: PLATAFORM; rPlatafrom: PLATAFORM_RESOURCES }
  ) {
    if (plataform) {
      // @ts-ignore
      state.PLATAFORM[plataform][rPlatafrom].push(item)
    } else {
      // @ts-ignore
      state[rType].push(item)
    }
  },
  UPDATE_RESOURCE (state: State,
    { index, rType, item, force = true, rPlatafrom, plataform }: { index: any; rType: RESOURCES; plataform?: PLATAFORM; rPlatafrom: PLATAFORM_RESOURCES; item: any; force: boolean }) {
    if (plataform) {
      if (force) {
        // @ts-ignore
        state.PLATAFORM[plataform][rPlatafrom][index] = item
      } else {
        Object.assign(state.PLATAFORM[plataform][rPlatafrom][index], item)
      }
    } else {
      if (force) {
        // @ts-ignore
        state[rType][index] = item
      } else {
        Object.assign(state[rType][index], item)
      }
    }
  },
  REMOVE_RESOURCE (
    state: State,
    { index, rType, rPlatafrom, plataform }: { index: number; rType: RESOURCES; plataform?: PLATAFORM; rPlatafrom: PLATAFORM_RESOURCES; }
  ) {
    if (plataform) {
      state.PLATAFORM[plataform][rPlatafrom].splice(index, 1)
    } else {
      state[rType].splice(index, 1)
    }
  }
}

export default mutations
