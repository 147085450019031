
// import { InventoryState } from '../../state'
import { CustomerState } from './state'

export default {
  SET_ALL (state: CustomerState, entities: any[]): void {
    state.customers = entities
  },
  PUSH (state: CustomerState, entitie: any): void {
    if (Array.isArray(entitie)) {
      // Insertar múltiples entidades al inicio
      state.customers = [...entitie, ...state.customers]
    } else {
      // Insertar un único elemento al inicio
      state.customers.unshift(entitie)
    }
  },
  UPDATE (state: CustomerState, entitie: any): void {
    const index = state.customers.findIndex(p => p.id === entitie.id)
    if (index !== -1) {
      state.customers.splice(index, 1, entitie)
    }
  }
}
