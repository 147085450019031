import Vue from 'vue'
import Vuex, { ActionContext } from 'vuex'
import app from './app'
import auth from './auth'
import monitoring from './monitoring'
import resources from './resources'
import travel from './travel'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import wialon from './wialon'
import mapon from './mapon'
import actualizer from './actualizer'
import geofences from './geofences'
import { IMessageEvent } from 'websocket'
import dashboard from './dashboard'
import administration from './administration'
import mantto from './mantto'
import types from './types'
import inventory from './inventory'
import cameras from './cameras'
import maps from './maps'
import { checklists } from './checklist'
import wheel from './wheel'
import battery from './battery'
import streamax from './streamax'
import { facturacion } from './facturacion'
import qr from './qr'
import asset_maintenance from './asset_maintenance'
import ticket from './ticket'
import area from './areas'
import issue from './asuntos'
import { unit_console } from './unit_console/index'
import chat from './chat'
import kpi from './kpi'
import jimiiot from './jimiiot'
import { CRM } from './crm'
import { eir } from './eir'
import { panel_control } from './panel_control'
// import { CRM } from './crm'
// eslint-disable-next-line @typescript-eslint/no-var-requires

Vue.use(Vuex)
const ls = new SecureLS({ isCompression: false })

export default new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['auth', 'app.logs'],
    storage: {
      // getItem: key => ls.get(key),
      // setItem: (key, value) => ls.set(key, value),
      // removeItem: key => ls.remove(key)
      getItem: (key) => sessionStorage.getItem(key),
      setItem: (key, value) => sessionStorage.setItem(key, value),
      removeItem: (key) => sessionStorage.removeItem(key)
    }
  })],
  actions: {
    async initApp ({ dispatch }: ActionContext<any, any>) {
      await dispatch('app/init', {}, { root: true })
      await dispatch('app/initSocket', (message: IMessageEvent) => {
        const data = JSON.parse(message.data.toString())
        dispatch('travel/events', data, { root: true })
      }, { root: true })
      await dispatch('app/initSocketByUser', (message: IMessageEvent) => {
        const data = JSON.parse(message.data.toString())
        dispatch('app/socketEvent', data, { root: true })
      }, { root: true })
      await dispatch('administration/init', {}, { root: true })
      await dispatch('geofences/loadGeofences', { root: true })
      await dispatch('travel/init', {}, { root: true })
      await dispatch('dashboard/init', {}, { root: true })
      await dispatch('mantto/init', {}, { root: true })
      await dispatch('types/init', {}, { root: true })
      await dispatch('qr/init', {}, { root: true })
      await dispatch('ticket/init', {}, { root: true })
      await dispatch('jimiiot/init', {}, { root: true })

      // Sin el await, para no tener que esperar a obtener todos los chats en el store
      dispatch('chat/init', {}, { root: true })
    }
  },
  modules: {
    app,
    auth,
    monitoring,
    resources,
    travel,
    chat,
    wialon,
    mapon,
    actualizer,
    dashboard,
    geofences,
    administration,
    mantto,
    types,
    cameras,
    maps,
    checklists,
    inventory,
    wheel,
    battery,
    // @ts-ignore
    streamax,
    facturacion,
    qr,
    asset_maintenance,
    ticket,
    area,
    unit_console,
    issue,
    kpi,
    jimiiot,
    CRM,
    eir,
    panel_control
  }
})
