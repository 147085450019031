import { IChecklist } from '@/classes/Checklist/Checklist'
import { ActionContext } from 'vuex'
import { State } from './state'

const URL_ACTION = '/apis/report_tasks/'
const actions = {
  async loadAll ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}get/all`,
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_ALL', data)
    return data
  },
  async create ({ dispatch, rootState, commit }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}create`,
          method: 'POST',
          data: payload
        },
        { root: true }
      )
      commit('PUSH', data)
      await dispatch('app/notification', { content: 'Éxito', type: 'success' }, { root: true })
    } catch (error) {
      console.error(error)
      await dispatch('app/notification', { content: 'No se ha podido guardar el checklist', type: 'error' }, { root: true })
      throw error
    }
  },
  async update ({ dispatch, rootState, commit }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}update/${payload.id}`,
          method: 'PUT',
          data: payload
        },
        { root: true }
      )
      commit('UPDATE', data)
      await dispatch('app/notification', { content: 'Éxito', type: 'success' }, { root: true })
    } catch (error) {
      await dispatch('app/notification', { content: 'No se ha podido actualizar el registro', type: 'error' }, { root: true })
      throw error
    }
  },
  async delete ({ commit, dispatch }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}delete/${payload.id}`,
          method: 'DELETE',
          data: payload
        },
        { root: true }
      )
      // @ts-ignore
      commit('DELETE', payload)
    } catch (error) {
      await dispatch('app/notification', { content: 'No se ha podido eliminar el registro', type: 'error' }, { root: true })
      throw error
    }
  },
  async generatePDF ({ dispatch }: ActionContext<State, string>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}generate-report`,
        method: 'POST',
        data: payload,
        responseType: 'blob'
      },
      { root: true }
    )
    const blob = new Blob([data], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'Reporte.pdf')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    return data
  }
}
export default actions
