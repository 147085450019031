/* eslint-disable no-use-before-define */
import Camera from '@/classes/Camera/Camera'
import IUnitConsoleCameraChannel from '@/interfaces/UnitConsoleCameraChannel.interface'
import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE'
import { UnitConsole } from '@/store/interfaces/ReportModules'
import { PLATAFORM } from '@/store/resources/RESOURCES'
import { IDriver } from '@/store/travel/state'

export default abstract class UnitConsoleData<RIType, RIData> implements UnitConsole {
  id: number
  unit_item!: RIType // Instancia de wialon de la unidad
  unit_resource_id: string
  plataform: string
  unit_km_limit: string
  unit_km_current: number;
  unit_allow_reset_km: string
  unit_hour_engine_limit: number
  unit_hour_engine_current: number
  unit_allow_reset_hour_engine: boolean
  unit_allow_confirm_reset_hour_engine: boolean
  unit_allow_confirm_reset_km: boolean
  unit_type: any
  wheels: any[];
  batteries: any[];
  unit_name: string;
  driver_profile: any;
  name_current: string
  icon: string
  cameras: Camera | null;
  drivers: IDriver[];
  ignition_value = -1
  qr_code: any
  sat_info: any
  isVisible: boolean
  checklist: any
  abstract pType: PLATAFORM

  // @ts-ignore
  last_message: RIData & { pos: { x: number; y: number; c: number } } = {
    pos: {
      x: 0, // Longitud
      y: 0, // Latitud
      c: 0 // Direccion
    }
  }

  cameraChannels: IUnitConsoleCameraChannel[] = []

  constructor (unitConsole: UnitConsole, properties: ReportItemProperties<RIType>, options = { initObservers: true }) {
    // @ts-ignore
    this.unit_item = properties.unitItem
    this.id = unitConsole.id
    this.plataform = unitConsole.plataform
    this.unit_km_limit = unitConsole.unit_km_limit
    this.unit_allow_reset_km = unitConsole.unit_allow_reset_km
    this.unit_allow_confirm_reset_km = unitConsole.unit_allow_confirm_reset_km
    this.unit_km_current = unitConsole.unit_km_current
    this.unit_hour_engine_limit = unitConsole.unit_hour_engine_limit
    this.unit_allow_reset_hour_engine = unitConsole.unit_allow_reset_hour_engine
    this.unit_allow_confirm_reset_hour_engine = unitConsole.unit_allow_confirm_reset_hour_engine
    this.unit_hour_engine_current = unitConsole.unit_hour_engine_current
    this.unit_resource_id = unitConsole.unit_resource_id
    this.wheels = unitConsole.wheels
    this.driver_profile = unitConsole.driver_profile
    this.batteries = unitConsole.batteries
    this.unit_name = unitConsole.unit_name
    this.unit_type = unitConsole.unit_type
    this.cameras = unitConsole.cameras ? new Camera(unitConsole.cameras) : null
    this.drivers = unitConsole.drivers ?? []
    this.qr_code = unitConsole.qr_code
    this.sat_info = unitConsole.sat_info
    this.checklist = unitConsole.checklist
    this.icon = this.getIcon()
    this.isVisible = false
    if (options.initObservers) {
      this.susbscribeToUnitMessages()
      this.initIntervalIgnitionObserver()
    }
    this.name_current = this.getName()
  }

  abstract unsusbscribeToUnitMessages(): void

  abstract susbscribeToUnitMessages(): void

  abstract getName(): string

  abstract getIcon(): string

  getPlataform () {
    if (this.plataform === 'WIALON') {
      return PLATAFORM.WIALON
    } else if (this.plataform === 'MAPON') {
      return PLATAFORM.MAPON
    }
  }

  setDrivers (drivers: any) {
    this.drivers = drivers
  }

  setDriver (driver: any) {
    this.driver_profile = driver
  }

  removeDriver (id: number) {
    this.driver_profile = null
  }

  getDrivers () {
    return this.drivers
  }

  setCameraChannels (channels: any) {
    this.cameraChannels = channels
  }

  addCamera (camera: any) {
    this.cameras = new Camera(camera)
  }

  deleteCameraById () {
    this.cameras = null
    this.cameraChannels = []
  }

  abstract getIgnitionState (): Promise<number>

  async initIntervalIgnitionObserver (): Promise<void> {
    try {
      const ignState = await this.getIgnitionState()
      this.ignition_value = ignState
      const intervalID = setInterval(() => {
        const timeout_id = setTimeout(async () => {
          try {
            const ignState = await this.getIgnitionState()
            this.ignition_value = ignState
          } catch (error) {
            console.error(error)
          }
        }, 0)
        // @ts-ignore
        this.ignition_timeout_id = timeout_id
      }, 7500)
      // @ts-ignore
      this.ignition_interval_id = intervalID
    } catch (error) {
      console.error(
        `No se pudo observar el estado de ignicion de la unidad ${this.unit_name}`,
        error
      )
    }
  }

  get isStopped () {
    return this.speed() <= 0
  }

  get cource () {
    return this.last_message.pos.c
  }

  abstract speed (): number

  abstract getDistanceByTime(params: { from: number, to: number }): Promise<number>

  haveHVEHomeIDCamera (EhomeIDs: string | string[]) {
    if (this.cameras === null) return null
    const camera = this.cameras
    if (Array.isArray(EhomeIDs)) {
      return EhomeIDs.includes(camera.cpt_properties?.EhomeID) ? camera : null
    } else {
      return camera.cpt_properties?.EhomeID === EhomeIDs ? camera : null
    }
  }

  haveUniqueIdCamera (uniqueIds: string | string[]) {
    if (this.cameras === null) {
      return null
    }
    const cameraValue = this.cameras
    if (Array.isArray(uniqueIds)) {
      return uniqueIds.includes(cameraValue.cpt_properties?.uniqueId) ? cameraValue : null
    } else {
      return cameraValue.cpt_properties?.uniqueId === uniqueIds ? cameraValue : null
    }
  }

  getHVEHomeIds () {
    const EhomeID = this.cameras?.cpt_properties?.EhomeID
    return EhomeID ? [EhomeID] : []
  }

  get hvCameras () {
    if (this.cameras === null) return []
    const cameraValues = Object.values(this.cameras)
    return cameraValues.filter(obj => obj.cpt_properties?.EhomeID || obj.cpt_properties?.uniqueId)
  }

  public setUnitData (data: any) {
    Object.assign(this, data)
  }
}

export interface ReportItemProperties<RIType> {
  type: PLATAFORM_TYPE
  unitItem?: RIType
  // @ts-ignores
  backRID?: ReportItemData<unknown, unknown>
}
