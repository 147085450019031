/* eslint-disable no-throw-literal */
/* eslint-disable no-new */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */

import { ActionContext } from 'vuex'
import { State } from './state'
import axios, { AxiosResponse } from 'axios'
// import StreamaxJSSdk from '@/submodules/streamax'
import UnitConsoleData from '../packages/ReportItem/ReportItemData'
import { CAMERA_TYPE } from '../catalogs/CAMERA_TYPE'
import StreamaxJSSdk from '@/submodules/streamax/dist'
import AAlert from '@/submodules/streamax/dist/actions/AAlert.action'
import { ET_STREAMAX } from '../catalogs/EVENTS_TYPES_STREAMAX'
import Notification from '@/classes/Notification/Notification'
import Camera from '@/classes/Camera/Camera'
import { getDateLocal } from '@/utils/datetimeToDate'
import { DateTime } from 'luxon'
const SING = '5b9e7fd4024fab27afe53472541d9c3f9ae5aab7c78d38c7004553bb6ec1e254651cce199b8b2e7e5271e24664f916ad2fa45dc023b700fe99dc06288bcd113c0a7510d51da7a497a7ef61a30aa8bc81d9d78c82b2dcd0e855ffc903be1e56a09d51461acb1c9304a2421bd78c2d07d98692f65eb17e2d6636464f427e8f376aa2f25af800656d49849f020e2f64b8de6984d9f69f9dcf13308d6ff515b0bdaf'
const TENAID = '1303'

export default {
  async init ({ commit, dispatch }: ActionContext<State, any>) {
    // @ts-ignore
    const sdk = new StreamaxJSSdk(SING, TENAID, { proxyUrl: 'apistreamax' })
    commit('SET_SDK', sdk)
    dispatch('initNotifications')
  },
  async initNotifications ({ rootState, dispatch }: ActionContext<State, any>) {
    const eventsArray = Object.values(ET_STREAMAX)
    const callback = async (alerts: AAlert[]) => {
      if (!rootState.cameras.streamax.disabledNotification) return
      for (let index = 0; index < alerts.length; index++) {
        const element = alerts[index]
        // console.log(element)
        const NOTIFICATION = eventsArray.find(eventArray => eventArray.code === element.alarmType)
        if (!NOTIFICATION) return
        const isNotEnabled:boolean = rootState.cameras.streamax.notifications_active[NOTIFICATION.type]
        if (!isNotEnabled) return
        const unitRegister = (rootState.travel.unitsRegisters.find((unit: { plataform: string, unit_resource_id: number }) => unit.plataform === 'STREAMAX' && String(unit.unit_resource_id) === element.uniqueId))
        const cam: Camera = unitRegister.cameras.find((cam: { cpt_properties: { uniqueId: string } }) => cam.cpt_properties.uniqueId && cam.cpt_properties.uniqueId === element.uniqueId)
        if (!unitRegister) return
        if (unitRegister && cam) {
          // const notification: INotification = {
          //   color: '#28affc',
          //   pType: PLATAFORM.STREAMAX,
          //   name: `${element.alarmType}`,
          //   // @ts-ignore
          //   nid: element.alert_type,
          //   p: {
          //     x: unitRegister.last_message.pos.x,
          //     y: unitRegister.last_message.pos.y
          //   },
          //   rt: 0,
          //   t: Date.now(),
          //   x: unitRegister.last_message.pos.x,
          //   y: unitRegister.last_message.pos.y,
          //   unit: unitRegister.unit_resource_id,
          //   tp: '',
          //   txt: `${element.alarmType}. El ${new Date(element.startTime).toLocaleString()}`,
          //   url: '',
          //   f: 0,
          //   blink: 0
          const notify = {
            id_unit: unitRegister.id,
            unit_name: unitRegister.name_current,
            cam_name: cam?.cpf_name,
            cam_id: element.uniqueId,
            notification: NOTIFICATION.name,
            message: NOTIFICATION.getNotName(),
            txt: NOTIFICATION.getNotMessage(),
            date: getDateLocal(element.startTime),
            icon: NOTIFICATION.icon,
            color: NOTIFICATION.color,
            urlMaps: `http://maps.google.com/?q=${element.gps.lat},${element.gps.lng}`
            // resources: await dispatch('cameras/getHikResources', { objname: toPascalCase(event.message?.data?.EventNotificationAlert?.eventType), event, unit }, { root: true })
          }
          for (let index = 0; index < rootState.cameras.notifications.length; index++) {
            const elementNotify:Notification = rootState.cameras.notifications[index]
            const isSameUnit = elementNotify.id_unit === notify.id_unit
            const isSameTypeNotify = elementNotify.notification === notify.notification
            const isSameCamID = elementNotify.cam_id === notify.cam_id
            if (isSameTypeNotify && isSameUnit && isSameCamID) {
              elementNotify.notification_child?.unshift(new Notification(notify))
              if (!rootState.cameras.streamax.disabledSoundNotification) return
              dispatch('app/playSound', { type: 'camera_alert' }, { root: true })
              return
            }
          }
          rootState.cameras.notifications.unshift(new Notification(notify))
          if (!rootState.cameras.streamax.disabledSoundNotification) return
          dispatch('app/playSound', { type: 'camera_alert' }, { root: true })
        }
      }
    }
    // @ts-ignore
    StreamaxJSSdk.actions.AAlert.addEventListener('ALERT_MESSAGE_CHANGED', callback)
  },
  async loadUnitsStreamaxSet ({ commit }: ActionContext<State, any>, uniqueIds = null) {
    try {
      const units = await StreamaxJSSdk.actions.AUnit.list({ uniqueIds })
      commit('SET_UNITS', units)
      return units
    } catch (error) {
      return []
    }
    // @ts-ignore
  },
  async loadUnitsStreamax ({ state }: ActionContext<State, any>, uniqueIds = null) {
    try {
      // @ts-ignore
      const { data } = await state.sdk.api.Device.list({ uniqueIds })
      return data
    } catch (error) {
      return []
    }
  },
  async getVideoChannelsStreamax ({ state, rootState, dispatch }: ActionContext<State, any>, payload: { uniqueId: string, id: number }) {
    try {
      const cameras_links = await state.sdk?.api.Media.queryStreamingVideoLink(payload.uniqueId, { channels: '1,2,3', streamingProtocol: 'FLV', quality: 'CLEAR' })
      // const cameras_links = { data: [{ channel: 1, url: '' }, { channel: 2, url: '' }] }
      // @ts-ignore
      const cameras = cameras_links.data.map((cam: any) => ({
        id_camera: `${cam.channel}`,
        cam_name: `${cam.channel}`,
        cam_type: CAMERA_TYPE.FLV,
        cam_url: cam.url,
        cam_edit: false,
        cam_delete: false,
        cam_data: cam,
        device: null,
        fk_camera_properties: 0
      }))
      // @ts-ignore
      const unitConsoleData: UnitConsoleData<unknown, unknown> = rootState.travel.unitsRegisters.find(obj => obj.id === payload.id)
      if (unitConsoleData) {
        unitConsoleData.setCameraChannels(cameras)
      }
      await dispatch('app/notifyToast', { msj: 'Exito!', type: 'success' }, { root: true })
      console.log(cameras)
      return cameras
    } catch (error) {
      console.error(error)
      await dispatch('app/notifyToast', { msj: 'Cámara fuera de linea', type: 'gray' }, { root: true })
      // await dispatch('app/notification', { content: 'N', type: 'error' }, { root: true })
      throw error
    }
  },
  async getRouteStreamax ({ state, dispatch }: ActionContext<State, any>, payload: { uniqueIds: string, startTime: string, endTime: string, alertTypes: [] }) {
    // @ts-ignore
    const { data } = await state.sdk?.api.Gps.gpsHistory({ ...payload, pageSize: 10000 })

    const { startTime: st, endTime: et } = payload
    data.event_incidents = []
    const startTime = new Date(st).toISOString()
    const endTime = new Date(et).toISOString()
    try {
      // @ts-ignore
      const data_alarm = await dispatch('getAlarmsHistory', { payload, alertTypes: payload.alertTypes.split(',').map(Number), startTime, endTime, uniqueId: payload.uniqueIds })

      // const data_alarm = await state.sdk?.api.Gps.alarmsHistory({ ...rest, startTime, endTime })
      data.event_incidents = { data: data_alarm }
    } catch (error) {
      console.error(error)
    }
    return data
  },
  async getAlarmsHistory (context: ActionContext<State, any>, payload: { uniqueId: string, startTime: Date, endTime: Date, alertTypes: number[] }) {
    // @ts-ignore
    const { startTime: st, endTime: et } = payload
    const startDate = DateTime.fromJSDate(st).toISO()
    const endDate = DateTime.fromJSDate(et).toISO()
    let data = []
    try {
      // @ts-ignore
      data = await axios.post('https://www.streamax.ananta.com.mx/streamax/device/alerts', {
        startDate,
        endDate,
        uniqueId: payload.uniqueId,
        alertTypes: payload.alertTypes
      })
    } catch (error) {
      console.error(error)
    }
    // @ts-ignore
    return data.data
  },
  async loadFleets ({ state }: ActionContext<State, any>) {
    // @ts-ignore
    const { data } = await state.sdk.api.Fleet.list()
    return data
  },
  async createVehicle ({ state }: ActionContext<State, any>, params: { fleetId: string, uniqueId: number, vehicleNumber: string }) {
    // @ts-ignore
    const { data } = await state.sdk.api.Vehicle.create(params)
    return data
  },
  async deleteVehicleByDevice ({ state }: ActionContext<State, any>, params: { unitqueIds: string }) {
    // @ts-ignore
    const { data } = await state.sdk.api.Device.delete(params)
    return data
  },
  // async acept ({ rootGetters, rootState, commit, dispatch }: ActionContext<State, any>) { }
  async saveEventStreamax ({ dispatch }: ActionContext<State, any>, payload:any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/camera/events_streamax',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
      // await dispatch('app/notification', { content: 'Éxito', type: 'success' }, { root: true })
      return data
      // commit('', data)
    } catch (error) {
      console.error(error)
      await dispatch('app/notification', { content: 'No se ha podido consultar', type: 'error' }, { root: true })
      dispatch('app/addLogWithError', { title: 'HIK_ADD_DEVICE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  }
  // initNotifications ({ dispatch, rootState }: ActionContext<State, any>, state: boolean) {
  //   const callback = async (alert: Alert) => {
  //     const unitRegister = (rootState.travel.unitsRegisters.find((unit: { plataform: string, unit_resource_id: number }) => unit.plataform === 'MAPON' && Number(unit.unit_resource_id) === alert.unit_id))
  //     if (!unitRegister) return
  //     const unit = await MaponSDK.actions.AUnit.getById(alert.unit_id)
  //     if (unit) {
  //       const notification: INotification = {
  //         color: '#28affc',
  //         pType: PLATAFORM.MAPON,
  //         name: alert.alert_type,
  //         // @ts-ignore
  //         nid: alert.alert_type,
  //         p: {
  //           x: unit.lng,
  //           y: unit.lat
  //         },
  //         rt: 0,
  //         t: Date.now(),
  //         x: unit.lng,
  //         y: unit.lat,
  //         unitData: unit,
  //         unit: unit.unit_id,
  //         tp: '',
  //         txt: `${alert.msg}. El ${new Date(alert.time).toLocaleString()}`,
  //         url: ''

  //       }
  //       await dispatch('wialon/addNotification', notification, { root: true })
  //     }
  //   }
  //   if (state) {
  //     MaponSDK.actions.AAlert.addEventListener('ALERT_MESSAGE_CHANGED', callback)
  //     // dispatch('sys/showNotificationMessage', {
  //     //   title: 'Notificaciones de mapon activadas',
  //     //   color: 'success'
  //     // }, { root: true })
  //   } else {
  //     MaponSDK.actions.AAlert.removeEventListener('ALERT_MESSAGE_CHANGED', callback)
  //     // dispatch('sys/showNotificationMessage', {
  //     //   title: 'Notificaciones de mapon desactivadas',
  //     //   color: 'success'
  //     // }, { root: true })
  //   }
  // },
  // async loadUnits ({ rootGetters, rootState, commit }: ActionContext<State, any>) {
  //   // @ts-ignore
  //   // const objects = await state.sdk
  //   const pUnits = await MaponSDK.actions.AUnit.list()
  //   commit(
  //     'resources/SET_RESOURCES',
  //     {
  //       resources: pUnits,
  //       plataform: PLATAFORM.MAPON,
  //       rPlatafrom: PLATAFORM_RESOURCES.UNITS
  //     },
  //     { root: true }
  //   )
  // },
  // async loadGeofences ({ rootGetters, rootState, commit }: ActionContext<State, any>) {
  //   const geofences = await MaponSDK.actions.AObjects.list()
  //   // const b:ICGeofence =
  //   const pGeofences: ICGeofence[] = geofences.map(geo => {
  //     const coordinates = formatGeofenceMapon(geo.wkt)
  //     const b = { cen_y: coordinates[0].lat, cen_x: coordinates[0].lng }
  //     return { geo_color: '#004CFFFF', geo_description: '', geo_name: geo.name, id: geo.id, n: geo.name, w: 30, b, geo_payload: { type: 2, coordinates } }
  //   })
  //   return pGeofences
  //   // commit(
  //   //   'resources/SET_RESOURCES',
  //   //   {
  //   //     resources: pGeofences,
  //   //     plataform: PLATAFORM.MAPON,
  //   //     rPlatafrom: PLATAFORM_RESOURCES.GEOFENCES
  //   //   },
  //   //   { root: true }
  //   // )
  // },
  // async loadDrivers ({ rootGetters, rootState, commit }: ActionContext<State, any>) {
  //   const pDrivers = await MaponSDK.actions.ADriver.list()
  //   commit(
  //     'resources/SET_RESOURCES',
  //     {
  //       resources: pDrivers,
  //       plataform: PLATAFORM.MAPON,
  //       rPlatafrom: PLATAFORM_RESOURCES.DRIVERS
  //     },
  //     { root: true }
  //   )
  //   // commit(
  //   //   'resources/SET_RESOURCES',
  //   //   {
  //   //     resources: pUnits,
  //   //     plataform: PLATAFORM.MAPON,
  //   //     rPlatafrom: PLATAFORM_RESOURCES.GEOFENCES
  //   //   },
  //   //   { root: true }
  //   // )
  // }
}
