
export interface SocialReasonState {
    accounts: any[]
}

const state = (): SocialReasonState => ({
  accounts: []
})
export type State = ReturnType<typeof state>;

export default state
