import { w3cwebsocket as WebSocketClient, IMessageEvent } from 'websocket'
import { ActionContext } from 'vuex/types/index'
import { State } from './state'
import { RESOURCES } from '@/store/resources/RESOURCES'
import { notifyToast } from '@/utils/notifications'

export default {
  initSocketAccount ({ dispatch, rootState }: ActionContext<State, any>) {
    const isHttps = window.location.protocol === 'https:'
    const protocol = isHttps ? 'wss:' : 'ws:'
    const host = process.env.VUE_APP_HOST || window.location.host
    const urlConection = `${protocol}//${host}/socket/account/${rootState.auth.account.id}`

    const client = new WebSocketClient(urlConection)

    client.onopen = function () {
      console.log('WebSocket Account Connected')
    }

    client.onerror = function (error) {
      dispatch('app/addLogWithError', { title: 'SOCKET_ACCOUNT_ERROR', color: 'error', message: '', error }, { root: true })
      console.log(error)
    }

    client.onclose = async function (event) {
      dispatch('app/addLogWithError', { title: 'SOCKET_ACCOUNT_CLOSE', color: 'info', message: '', error: event }, { root: true })
      console.log(event)
      // Se reintentara la coneccion en 5 segundos si el chat existe
      setTimeout(() => dispatch('initSocketAccount'), 5000)
    }

    client.onmessage = function (event: IMessageEvent) {
      const data: { data: any; event: string } = JSON.parse(event.data.toString())
      const objEventsActions: { [key: string]: string } = {
        connected: 'socket_connected',
        travel_assigned: 'socket_TravelAssigned',
        travel_canceled: 'socket_TravelCanceled'
      }
      dispatch(`${objEventsActions[data.event]}`, data.data)
    }
  },
  async socket_TravelAssigned ({ dispatch }: ActionContext<State, any>) {
    notifyToast({ msj: 'Viaje Creado', type: 'success' })
    await dispatch('app/playSound', { type: 'done' }, { root: true })
    await dispatch('travel/loadTravels', null, { root: true })
    await dispatch('travel/getWialonResourcesIdsAndUnits', null, { root: true })
  },
  socket_TravelCanceled ({ commit, rootState, dispatch }: ActionContext<State, any>, travel_id: number) {
    const index = rootState.resources[RESOURCES.TRAVELS].findIndex(
      (tra: { travel_id: number }) => tra.travel_id === travel_id
    )
    if (index === -1) {
      return
    }

    commit(
      'resources/REMOVE_RESOURCE',
      {
        index,
        rType: RESOURCES.TRAVELS
      },
      { root: true }
    )
    notifyToast({ msj: 'Viaje Eliminado', type: 'error' })
    dispatch('app/playSound', { type: 'done' }, { root: true })
  }
}
