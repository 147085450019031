
export interface DashboardState {
  dashboard: any[]
}

const state = (): DashboardState => ({
  dashboard: []
})
export type State = ReturnType<typeof state>;

export default state
