
import { Module } from 'vuex'
// @ts-ignore
import { RootState } from '@/store/types'
import actions from './actions'
import state, { DashboardState } from './state'

export const dashboard: Module<DashboardState, RootState> = {
  namespaced: true,
  state,
  // getters,
  actions
}
