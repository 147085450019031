import { RouteConfig } from 'vue-router'
import store from '@/store/index'
import Vue from 'vue'
import loginToken from '@/utils/wialonSDK/loginToken'
import { USER_TYPES } from '@/store/auth/USER_TYPES'
import facturaRoutes from './routes/facturacion/FacturaRoute'
import noteSaleRoutes from './routes/facturacion/NoteSaleRoute'
import quotationRoutes from './routes/facturacion/QuotationRoute'
import pagoRoutes from './routes/facturacion/PagoRoute'
import cartaPorteRoutes from './routes/facturacion/CartaPorteRoute'
import mantenimientoRoutes from './routes/mantenimiento/MantenimientoRoutes'
import reporteRoutes from './routes/facturacion/ReporteRoute'

const Login: RouteConfig = {
  path: '/Consola',
  name: 'Console',
  children: [
    {
      meta: {
        title: 'baterias'
      },
      path: 'baterias',
      name: 'baterias',
      component: () => import('@/pages/Battery/index.vue')
    },
    {
      meta: {
        title: 'Neumaticos'
      },
      path: 'neumaticos',
      name: 'neumaticos',
      component: () => import('@/pages/Wheel/index.vue')
    },
    {
      meta: {
        title: 'Usuarios'
      },
      path: 'usuarios',
      name: 'usuarios',
      component: () => import('@/pages/Administration/UsersByClient/index.vue')
    },
    {
      meta: {
        title: 'Geocercas'
      },
      path: 'geocercas',
      name: 'geocercas',
      component: () => import('@/pages/Resources/Geofence/index.vue')
    },
    {
      meta: {
        title: 'CheckList'
      },
      path: 'checklist',
      name: 'checklist',
      component: () => import('@/pages/Checklist/index.vue'),
      children: [
        {
          path: 'control_tareas',
          name: 'control_tareas',
          component: () => import('@/components/Checklist/modules/tasks/dashboard.vue'),
          meta: {
            title: 'Tareas'
          }
        },
        {
          path: 'seguimiento_tareas',
          name: 'seguimiento',
          component: () => import('@/components/Checklist/modules/tasks/dashboard_user.vue'),
          meta: {
            title: 'Tareas'
          }
        },
        {
          path: 'reportes_tareas',
          name: 'reportes',
          component: () => import('@/components/Checklist/modules/tasks/task_Report.vue'),
          meta: {
            title: 'Reportes'
          }
        },
        {
          path: 'apartados',
          name: 'apartados',
          component: () => import('@/components/Checklist/modules/checklists/checklist_modules.vue'),
          meta: {
            title: 'Apartados'
          }
        }
      ]
    },
    {
      meta: {
        title: 'CheckList'
      },
      path: 'new_checklist',
      name: 'new_checklist',
      props: true,
      component: () => import('@/components/Checklist/CLForms/Create.vue')
    },
    {
      meta: {
        title: 'Tareas'
      },
      path: 'tareas',
      name: 'tareas',
      component: () => import('@/components/Checklist/modules/tasks/Task.vue'),
      children: [
        {
          path: 'control_tares',
          name: 'tasks',
          props: true, // Habilita la recepción de props
          component: () => import('@/components/Checklist/modules/tasks/task_Admin.vue'),
          meta: {
            title: 'Tareas'
          }
        },
        {
          path: 'asignadas',
          name: 'pendientes',
          props: true, // Habilita la recepción de props
          component: () => import('@/components/Checklist/modules/tasks/task_User.vue'),
          meta: {
            title: 'Tareas en curso'
          }
        }
      ]
    },
    {
      meta: {
        title: 'Viajes'
      },
      path: 'viajes',
      name: 'viajes',
      component: () => import('@/pages/Travels/index.vue')
    },
    {
      meta: {
        title: 'Detalles de Viaje'
      },
      path: 'viaje_details',
      name: 'viaje_details',
      component: () => import('@/components/Travels/Travels/TravelDetails.vue'),
      props: true
    },
    {
      meta: {
        title: 'Estadisticas'
      },
      path: 'estadisticas',
      name: 'estadisticas',
      component: () => import('@/pages/Statistics/index.vue')
    },
    {
      meta: {
        title: 'Cámaras'
      },
      path: 'camaras',
      name: 'camaras',
      component: () => import('@/pages/Cameras/CameraView.vue')
    },
    {
      meta: {
        title: 'Eventos'
      },
      path: 'eventos',
      name: 'eventos',
      component: () => import('@/pages/Cameras/CameraEvents.vue')
    },
    {
      meta: {
        title: 'Control de datos'
      },
      path: 'control',
      name: 'control',
      component: () => import('@/pages/Cameras/CameraChart.vue')
    },
    {
      meta: {
        title: 'Mapas'
      },
      path: 'mapas',
      name: 'mapas',
      component: () => import('@/pages/Maps/index.vue')
    },
    {
      meta: {
        title: 'Combustible'
      },
      path: 'combustible',
      name: 'combustible',
      component: () => import('@/pages/Units/Combustible/index.vue')
    },
    mantenimientoRoutes,
    {
      meta: {
        title: 'Inventario'
      },
      path: 'inventario',
      name: 'inventario',
      component: () => import('@/pages/Inventories/index.vue'),
      children: [
        {
          meta: {
            title: 'QR'
          },
          path: 'qr',
          name: 'qr',
          component: () => import('@/components/Inventory/panel_menus/Qr_info.vue')
        },
        {
          meta: {
            title: 'Requisiciones'
          },
          path: 'requisition',
          name: 'requisition',
          component: () => import('@/components/Inventory/modules/requisition/RequisitionList.vue')
        },
        {
          meta: {
            title: 'Ordenes de compra'
          },
          path: 'orders',
          name: 'orders',
          component: () => import('@/components/Inventory/panel_menus/Order.vue')
        },
        {
          meta: {
            title: 'Entrada de inventario'
          },
          path: 'entry-store',
          name: 'entry-store',
          component: () => import('@/components/Inventory/panel_menus/EntryStoreList.vue')
        },
        {
          meta: {
            title: 'Inventarios'
          },
          path: 'inventories',
          name: 'inventories',
          component: () => import('@/components/Inventory/panel_menus/Inventories.vue')
        }
        // {
        //   meta: {
        //     title: 'dashboard_inventories'
        //   },
        //   path: 'dashboard_inventories',
        //   name: 'dashboard_inventories',
        //   component: () => import('@/components/Inventory/panel_menus/dashboard_inventories.vue')
        // }
      ]
    },
    {
      meta: {
        title: 'Control de Activos'
      },
      path: 'control_activos',
      name: 'control_activos',
      component: () => import('@/pages/Mantenimientos/index.vue')
    },
    {
      meta: {
        title: 'Mantenimientos Completos'
      },
      path: 'mantenimientos',
      name: 'mantenimientos',
      component: () => import('@/components/Asset_maintenance/Maintenances/AssetMaintenance.vue'),
      children: [
        {
          path: 'details',
          name: 'maintenance_details',
          component: () => import('@/components/Asset_maintenance/forms/GeneralDataMantto.vue'),
          meta: {
            title: 'Detalles de Mantenimiento'
          },
          props: true
        },
        {
          path: 'complete',
          name: 'maintenance_complete',
          component: () => import('@/components/Asset_maintenance/Maintenances/AssetComplete.vue'),
          meta: {
            title: 'Detalles de Mantenimiento'
          },
          props: true
        },
        {
          path: 'progress',
          name: 'maintenance_progress',
          component: () => import('@/components/Asset_maintenance/Maintenances/AssetProgress.vue'),
          meta: {
            title: 'Detalles de Mantenimiento'
          },
          props: true
        }
      ]
    },
    {
      meta: {
        title: 'Facturación'
      },
      path: 'facturacion',
      name: 'facturacion',
      component: () => import('@/pages/Facturacion/index.vue')
    },
    quotationRoutes,
    noteSaleRoutes,
    facturaRoutes,
    pagoRoutes,
    cartaPorteRoutes,
    reporteRoutes,
    {
      meta: {
        title: 'Productos'
      },
      path: 'productos',
      component: () => import('@/components/Facturacion/modules/products/Products.vue'),
      children: [
        {
          meta: {
            title: 'Productos'
          },
          path: '',
          name: 'productos',
          component: () => import('@/components/Facturacion/modules/products/ProductsList.vue')
        },
        {
          path: 'nuevo-producto',
          name: 'nuevo-producto',
          meta: {
            title: 'Nuevo producto'
          },
          component: () => import('@/components/Facturacion/modules/products/ProductForm.vue')
        }
      ]
    },
    {
      meta: {
        title: 'Clientes'
      },
      path: 'customers',
      component: () => import('@/components/Facturacion/modules/customer/Customer.vue'),
      children: [
        {
          meta: {
            title: 'Clientes'
          },
          path: '',
          name: 'customers',
          component: () => import('@/components/Facturacion/modules/customer/CustomerList.vue')
        },
        {
          path: 'nuevo-cliente',
          name: 'nuevo-cliente',
          meta: {
            title: 'Nuevo cliente'
          },
          component: () => import('@/components/Facturacion/modules/customer/CustomerForm.vue')
        }
      ]
    },
    {
      path: 'nueva-requisicion',
      name: 'nueva-requisicion',
      meta: {
        title: 'Requisicion'
      },
      component: () => import('@/components/Inventory/modules/requisition/RequisitionForm.vue')
    },
    {
      path: 'users',
      name: 'Cms',
      meta: {
        title: 'Usuarios'
      },
      component: () => import('@/pages/Administration/modules/user/UserList.vue')
    },
    {
      path: 'account',
      name: 'Cms',
      meta: {
        title: 'Administracion'
      },
      component: () => import('@/pages/Administration/modules/account/AccountList.vue')
    },
    {
      path: 'panel_control',
      meta: {
        title: 'Panel de Control'
      },
      component: () => import('@/pages/Panel_Control/index.vue'),
      children: [
        {
          path: '',
          name: 'info',
          meta: {
            title: 'Información'
          },
          component: () => import('@/components/Panel_Control/Panel_Info.vue')
        }
      ]
    },
    {
      path: 'tickets',
      name: 'tickets',
      meta: {
        title: 'Tickets'
      },
      component: () => import('@/pages/Tickets/index.vue'),
      children: [
        {
          meta: {
            title: 'Asuntos'
          },
          path: 'issue_titles',
          props: true, // Habilita la recepción de props
          name: 'issue_titles',
          component: () => import('@/components/Tickets/modules/Asuntos/Issue_title_General.vue')
        },
        {
          meta: {
            title: 'All Tickets'
          },
          path: 'all-tickets',
          props: true, // Habilita la recepción de props
          name: 'all-tickets',
          component: () => import('@/components/Tickets/modules/Tickets/TicketsGeneral.vue')
        },
        {
          meta: {
            title: 'Tickets KPI'
          },
          path: 'kpi',
          props: true, // Habilita la recepción de props
          name: 'kpi',
          component: () => import('@/components/Tickets/modules/KPI/TicketsKPIs.vue')
        },
        {
          meta: {
            title: 'nuevo ticket'
          },
          path: 'new-ticket',
          name: 'new-ticket',
          component: () => import('@/components/Tickets/modules/Tickets/forms/TicketsForm.vue')
        },
        {
          path: 'dashboard',
          name: 'dashboard',
          meta: {
            title: 'Dashboard'
          },
          component: () => import('@/components/Tickets/modules/Tickets/TicketsDashboard.vue')
        },
        {
          path: 'conversation/:ticketId/:timestamp?',
          name: 'conversation',
          props: true, // permite pasar parámetros como props
          meta: {
            title: 'Conversation'
          },
          component: () => import('@/components/Tickets/modules/Tickets/TicketsComments.vue')
        }
      ]
    },
    {
      path: 'CRM',
      name: 'CRM',
      meta: {
        title: 'CRM'
      },
      component: () => import('@/pages/CRM/index.vue')
    },
    {
      path: 'Marketing',
      name: 'Marketing',
      meta: {
        title: 'Marketing'
      },
      component: () => import('@/components/CRM/Marketing/Marketing.vue'),
      children: [
        {
          path: '',
          name: 'marketing_list',
          meta: {
            title: 'Marketing'
          },
          component: () => import('@/components/CRM/Marketing/MarketingList.vue')
        }
      ]
    },
    {
      path: 'ventas',
      name: 'ventas',
      meta: {
        title: 'Ventas'
      },
      component: () => import('@/components/CRM/Ventas/projects/Ventas.vue'),
      children: [
        {
          path: 'ventas_list/:saleIdN?',
          name: 'ventas_list',
          meta: {
            title: 'Listado'
          },
          props: true,
          component: () => import('@/components/CRM/Ventas/projects/LeadVentas.vue')
        },
        {
          path: 'calendar',
          name: 'calendar',
          meta: {
            title: 'Calendario'
          },
          props: true,
          component: () => import('@/components/CRM/Ventas/projects/VentasRecordatorio.vue')
        },
        {
          path: 'info_venta/:saleId',
          name: 'info_venta',
          meta: {
            title: 'Información de Venta'
          },
          props: true,
          component: () => import('@/components/CRM/Ventas/projects/VentasById.vue')
        },
        {
          path: 'dashboard_sale',
          name: 'dashboard_sale',
          meta: {
            title: 'Dashboard Ventas'
          },
          component: () => import('@/components/CRM/Ventas/dashboard/dashboard.vue')
        }
      ]
    },
    {
      meta: {
        title: 'EIR'
      },
      path: 'eir',
      component: () => import('@/components/EIR/EIR.vue'),
      children: [
        {
          meta: {
            title: 'EIR'
          },
          path: '',
          name: 'eir',
          component: () => import('@/components/EIR/EIRDashboard.vue')
        },
        {
          meta: {
            title: 'LISTA DE EIR'
          },
          path: 'eir_list',
          name: 'eir_list',
          component: () => import('@/components/EIR/EIRList.vue')
        },
        {
          meta: {
            title: 'EIR CHECKLIST'
          },
          props: true,
          path: 'eir_create_checklist',
          name: 'eir_create_checklist',
          component: () => import('@/components/EIR/form/EIR_FORM_CHECKLIST.vue')
        }
      ]
    }
  ],
  component: () => import('@/pages/Console.vue'),
  beforeEnter: async (to, from, next) => {
    // @ts-ignore
    if (!store.state.auth.is_auth) {
      next({
        replace: true,
        name: 'Login'
      })
    } else {
      try {
        // @ts-ignore
        await Vue.loadScript('//hst-api.wialon.com/wsdk/script/wialon.js')
        // @ts-ignore
        await store.dispatch('auth/updateAuth', !!store.state.auth.userBaseAuth)
        // @ts-ignore
        if (!store.state.auth.login_cms) {
          // @ts-ignore
          store.state.auth.reload = false
          // @ts-ignore
          store.commit('app/UPDATE_LOADER', {
            loader: true,
            message: 'Cargando Librerías'
          })
          // @ts-ignore
          const sess = wialon.core.Session.getInstance()
          sess.initSession('https://hst-api.wialon.com') // initialize Wialon session
          sess.loadLibrary('itemIcon') // load Icon Library
          sess.loadLibrary('resourceNotifications') // load Notification Library
          sess.loadLibrary('unitSensors') // load Sensor Library
          sess.loadLibrary('resourceDrivers')
          sess.loadLibrary('resourceZones') // load Geofences Library
          sess.loadLibrary('resourceZoneGroups') // load Geofences Library
          sess.loadLibrary('itemDriver') // load Geofences Library
          try {
            await loginToken(
              sess,
              store.getters['auth/wtoken']
            )
            store.commit('app/UPDATE_LOADER', {
              loader: true,
              message: 'Descargando Recursos'
            })
          } catch (e) {
            if (e === 'Invalid user name or password') {
              await store.dispatch('app/notification', {
                content: 'Token de Wialon no valido.',
                type: 'error',
                timeout: 2000
              })
            }
          }
          // await store.dispatch('app/initNotifyFirebase')
          await store.dispatch('mapon/init', {}, { root: true })
          await store.dispatch('streamax/init', {}, { root: true })
          await store.dispatch('initApp')
        } else {
          await store.dispatch('streamax/init', {}, { root: true })
          await store.dispatch('administration/init', {}, { root: true })
        }
        // else if (store.getters['auth/roleId'] === USER_TYPES.ADMINISTRADOR) {
        //   await store.dispatch('streamax/init', {}, { root: true })
        //   await store.dispatch('administration/init', {}, { root: true })
        // }
        await store.dispatch('maps/requestGeolocationPermission')
      } catch (error) {
        store.commit('app/ADD_LOG', { title: 'INIT_CONSOLE', color: 'error', message: error })
        console.error(error)
      } finally {
        store.commit('app/UPDATE_LOADER', {
          loader: true,
          message: 'Bienvenido!'
        })
        setTimeout(() => {
          store.commit('app/UPDATE_LOADER', {
            loader: false
          })
          next()
        }, 1500)
      }
    }
  }
}

export default Login
