
export interface EirContainerState {
  eir_container: any[]
}

const state = (): EirContainerState => ({
  eir_container: []
})
export type State = ReturnType<typeof state>;

export default state
