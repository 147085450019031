import { Module } from 'vuex'
// @ts-ignore
import { RootState } from '@/store/types'
import { dashboard } from './modules/dashboard'

export const panel_control: Module<any, RootState> = {
  namespaced: true,
  modules: {
    dashboard
  }
}
