// import { InventoryState } from '../../state'

import { EirContainerState } from './state'

// import { ProductState } from './state'

export default {
  // getNoteSales (state: CartaPorteState) {
  //   return state.facturas
  // }
}
