
import { ActionContext } from 'vuex'
import { State } from './state'
const URL_ACTION = '/apis/panel_control/'
const actions = {
  async loadAll ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async loadManttos ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}mantto`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async loadInventory ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}`,
        method: 'GET'
      },
      { root: true }
    )
    return data
  }
}
export default actions
