
export interface SeguroState {
  seguros: any[]
}

const state = (): SeguroState => ({
  seguros: []
})
export type State = ReturnType<typeof state>;

export default state
