
import { Module } from 'vuex'
// @ts-ignore
import { RootState } from '@/store/types'
import actions from './actions'
import state, { SeguroState } from './state'

export const seguro: Module<SeguroState, RootState> = {
  namespaced: true,
  state,
  actions
}
